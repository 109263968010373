import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fader } from './animations/router-animation/router-animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fader,
  ]
})
export class AppComponent {
  title = 'uv-ng-material-template';
  prepareAnimations(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData;
  }
}
