<section  [@routerAnimations]="prepareAnimations(outlet)">
  <router-outlet #outlet="outlet">
  </router-outlet>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="medium"
             color="#0058FF"
             loadingText="Please wait"
             type="ball-clip-rotate">
  <!-- <p style="font-size: 20px; color: #F68428">Loading...</p> -->
</ngx-spinner>