<mat-sidenav-container class="app-sidenav-container">

  <!-- Sidenav Starts -->
  <mat-sidenav [opened]="!mobileQuery.matches" class="mat-elevation-z2 px-0" #snav
    [mode]="mobileQuery.matches ? 'over' : 'side'" id="side-nav" [opened]="true">
    <div class="app-brand d-flex align-items-center">
      <img src="/assets/logos/LOGO_FINAL_01.png" decoding="async" width="1%" loading="lazy" alt="MOR">
      <span>
        &nbsp;&nbsp; {{ UserDetail.role }}
      </span>
    </div>

    <!-- NavList -->
    <mat-nav-list>
      <ng-container *ngFor="let navItem of menuItems">
       <div *ngIf="navItem.path.length === 0">
          <a mat-list-item (click)="openSubMenu(navItem,menuItems)">
            <span class="d-flex mat-list-item-content">
              <mat-icon>{{navItem.icon}}</mat-icon>
              <span class="sidenav-navlink-text pl-2">{{navItem.text}}</span>
              <mat-icon class="ml-2" *ngIf="!navItem.isSubMenuOpen">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="navItem.isSubMenuOpen" class="ml-2">keyboard_arrow_right</mat-icon>
            </span>
            <!-- Submneu -->
          </a>
          <ng-container *ngIf="navItem.isSubMenuOpen">
            <section [@openClose]="navItem.isSubMenuOpen ? 'open' : 'closed'" class="pl-4"> 
              <a mat-list-item *ngFor="let subNavItem of navItem.subMenu" routerLinkActive="active-link">
                <div *ngIf="subNavItem.path.length === 0 ">
                  
                  <a  mat-list-item (click)="openSubMenu(subNavItem,navItem.subMenu)">
                    <span class="d-flex mat-list-item-content">
                      <mat-icon>{{subNavItem.icon}}</mat-icon>
                      <span class="sidenav-navlink-text pl-2">{{subNavItem.text}}</span>
                    </span>
                    <!-- Submneu -->
                  </a>
                
                  <!-- <ng-container *ngIf="subNavItem.isSubMenuOpen">
                    <section [@openClose]="subNavItem.isSubMenuOpen ? 'open' : 'closed'" class="pl-4"> 
                      <a mat-list-item *ngFor="let subSubNavItem of subNavItem.subMenu" [routerLink]="subSubNavItem.path" routerLinkActive="active-link">
                        <span class="d-flex mat-list-item-content ml-3">
                          <mat-icon>{{subSubNavItem.icon}}</mat-icon>
                          <span class="sidenav-navlink-text pl-3">{{subSubNavItem.text}}</span>
                        </span>
                      </a>
                    </section>
                  </ng-container> -->
                </div>
        
                <!-- Normal Link -->
                <a mat-list-item *ngIf="subNavItem.path.length !== 0" [routerLink]="subNavItem.path" routerLinkActive="active-link">
                  <span   class="d-flex mat-list-item-content">
                    <mat-icon>{{subNavItem.icon}}</mat-icon>
                    <span class="sidenav-navlink-text pl-2">{{subNavItem.text}}</span>
                  </span>
                </a>
              </a>
            </section>
          </ng-container>
        </div>

        <!-- Normal Link -->
        <a mat-list-item *ngIf="navItem.path.length !== 0 " [routerLink]="navItem.path" routerLinkActive="active-link">
          <span class="d-flex mat-list-item-content">
            <mat-icon>{{navItem.icon}}</mat-icon>
            <span class="sidenav-navlink-text pl-2">{{navItem.text}}</span>
          </span>
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Sidenav Ends -->
  <mat-sidenav-content class="app-sidenav-content">
    <app-topnav [snav]="snav" [UserDetail]="UserDetail"></app-topnav>
    <main class="d-flex flex-column h-100" [@routerAnimations]="prepareAnimations(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>