import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppShellModule } from './components/app-shell/app-shell.module';
import { ApproveDialogComponent } from './event-creation/VIEWING_SCREEN/view-event/approve-dialog/approve-dialog.component';
import { RejectDialogComponent } from './event-creation/VIEWING_SCREEN/view-event/reject-dialog/reject-dialog.component';
import { ApproveF2Component } from './event-creation/VIEWING_SCREEN/view-f2-event/approve-f2/approve-f2.component';
import { RejectF2Component } from './event-creation/VIEWING_SCREEN/view-f2-event/reject-f2/reject-f2.component';
import { F2MatchesComponent } from './f2-matches/f2-matches.component';
import { EditCategoryComponent } from './gift/edit-category/edit-category.component';
import { GifComponent } from './gift/gif/gif.component';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { HostSalaryReportComponent } from './host-salary-report/host-salary-report.component';
import { HostSalaryReportModule } from './host-salary-report/host-salary-report.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
// import { ClubCommissionReportComponent } from './club-commission-report/club-commission-report.component';


@NgModule({
  declarations: [
    AppComponent,
    F2MatchesComponent,
    // ClubCommissionReportComponent,
  ],
  entryComponents: [EditCategoryComponent,
    GifComponent, ApproveDialogComponent, RejectDialogComponent, ApproveF2Component, RejectF2Component]
  ,
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppShellModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSortModule,
    MatSelectModule,
    MatButtonModule,
    HostSalaryReportModule,
    MatTabsModule,
    MatMenuModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
