import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppShellComponent } from './components/app-shell/app-shell/app-shell.component';
import { AuthGuard } from './services/auth/auth.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./auth/signup/signup.module').then(m => m.SignupModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module')
      .then(m => m.ForgotPasswordModule),
  },
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'products',
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'banners',
        loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'streams',
        loadChildren: () => import('./streams/streams.module').then(m => m.StreamsModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'staff',
        loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'vip',
        loadChildren: () => import('./vip/vip.module').then(m => m.VipModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'country',
        loadChildren: () => import('./pages/country/country.module').then(m => m.CountryModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'beansDiamond',
        loadChildren: () => import('./pages/beans-diamond/beans-diamond.module').then(m => m.BeansDiamondModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'diamondBean',
        loadChildren: () => import('./pages/diamond-bean/diamond-bean.module').then(m => m.DiamondBeanModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'diamondPurchase',
        loadChildren: () => import('./pages/diamond-purchase/diamond-purchase.module').then(m => m.DiamondPurchaseModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'diamondFiat',
        loadChildren: () => import('./pages/diamond-fiat/diamond-fiat.module').then(m => m.DiamondFiatModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'rates',
        loadChildren: () => import('./master-rates/master-rates.module').then(m => m.MasterRatesModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'violation',
        loadChildren: () => import('./pages/violation/violation.module').then(m => m.ViolationModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'currencyExchange',
        loadChildren: () => import('./currency-exchange/currencyexchange.module').then(m => m.CurrencyexchangeModule)
      },
      {
        path: 'club',
        loadChildren: () => import('./club-mgnt-list/club-mgnt.module').then(m => m.ClubMgntModule)
      },
      {
        path: 'taskBoard',
        loadChildren: () => import('./pages/task-board/task-board.module').then(m => m.TaskBoardModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'starLive',
        loadChildren: () => import('./pages/star-live-report/star-live-report.module').then(m => m.StarLiveReportModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'luckyDraw',
        loadChildren: () => import('./pages/lucky-draw-report/lucky-draw-report.module').then(m => m.LuckyDrawReportModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'salaryReport',
        loadChildren: () => import('./host-salary-report/host-salary-report.module').then(m => m.HostSalaryReportModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'clubRewardReport',
        loadChildren: () => import('./club-reward-report/club-reward-report.module').then(m => m.ClubRewardReportModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'clubCommissionReport',
        loadChildren: () => import('./club-commission-report/club-commission-report.module').then(m => m.ClubCommissionReportModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'applicationList',
        loadChildren: () => import('./pages/applications-list/applications-list.module').then(m => m.ApplicationsListModule),
      },
      {
        path: 'clubList',
        loadChildren: () => import('./pages/club-management/club-management.module').then(m => m.ClubManagementModule),
      },
      {
        path: 'zones',
        loadChildren: () => import('./zones/zones.module').then(m => m.ZonesModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'gift',
        loadChildren: () => import('./gift/gift.module').then(m => m.GiftModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },

      {
        path: 'livegift',
        loadChildren: () => import('./livegifts/livegifts.module').then(m => m.LivegiftsModule),
        //canLoad: [AuthGuard],
        canLoad: [AuthGuard],
      },

      {
        path: 'giftRequest',
        loadChildren: () => import('./gift-order/gift-order.module').then(m => m.GiftOrderModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'star-live',
        loadChildren: () => import('./star-live/star-live.module').then(m => m.StarLiveModule),
        //canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'f2-matches',
        loadChildren: () => import('./f2-matches/f2-matches.module').then(m => m.F2MatchesModule),
        //canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
        // canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'starliveScreen',
        loadChildren: () => import('./starlive/starlive.module').then(m => m.StarliveModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'eventFixtures',
        loadChildren: () => import('./starlive/starlive.module').then(m => m.StarliveModule),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'f2',
        loadChildren: () => import('./officialf2/officialf2.module').then(m => m.Officialf2Module),
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'events',
        loadChildren: () => import('./event-creation/event-creation.module').then(m => m.EventCreationModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'eventsManagement',
        loadChildren: () => import('./event-management/event-management.module').then(m => m.EventManagementModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'talents',
        loadChildren: () => import('./talent-tags/talent-tags.module').then(m => m.TalentTagsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'salary',
        loadChildren: () => import('./salary/salary.module').then(m => m.SalaryModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'reward',
        loadChildren: () =>
          import('./reward-slab/reward-list.module').then(m => m.RewardListModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'reward-slab-host',
        loadChildren: () =>
          import('./reward-slab-host/reward-slab-host.module').then(m => m.RewardSlabHostModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'vip-rewards',
        loadChildren: () =>
          import('./vip-rewards/vip-rewards.module').then(m => m.VipRewardsModule)
      },
      {
        path: 'vip-subscription-offers',
        loadChildren: () =>
          import('./vip-subscription-offers/vip-subscription-offers.module').then(m => m.VipSubscriptionOffersModule)
      },
      {
        path: 'family',
        loadChildren: () =>
          import('./familly/familly.module').then(m => m.FamillyModule)
      },
      {
        path: 'dummy',
        loadChildren: () =>
          import('./dummy-page/dummy/dummy.module').then(m => m.DummyModule)
      },
      {
        path: 'task',
        loadChildren: () => import('./task-management/task-management.module').then(m => m.TaskManagementModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'user-transaction',
        loadChildren: () => import('./user-transaction/user-transaction.module').then(m => m.UserTransactionModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      }

    ]
  },

  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
