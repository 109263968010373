import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

export interface BannerQueryParams {
  sortField?: string;
  sortValue?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  uri = `${this.settingsServe.API_BASE_URL}/banners`;

  constructor(
    private settingsServe: SettingsService,
    private http: HttpClient
  ) { }

  uploadGitImage(gifFile: File): Promise<any> {
    const data = new FormData();
    data.append('image', gifFile);
    return this.http.post(this.uri, {}).toPromise();
  }

  addBanner(data: any): Promise<any> {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item: any) => {
          formData.append(key, item);
        });
        return;
      }
      formData.append(key, data[key]);
    });
    return this.http.post(this.uri, formData).toPromise();
  }

  updateBanner(id: string, data: any): Promise<any> {
    console.log(data);
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item: any) => {
          formData.append(key, item);
        });
        return;
      }
      formData.append(key, data[key]);
    });
    return this.http.patch(`${this.uri}/${id}`, formData).toPromise();
  }

  getBanners(params: BannerQueryParams = {}): Promise<any> {
    return this.http.get(this.uri, { params }).toPromise();
  }

  getBannerById(id: string): Promise<any> {
    return this.http.get(`${this.uri}/${id}`).toPromise();
  }
  // For Events
  getPhysicalBannersForEventById(params = {}): Promise<any> {
    return this.http.get<any>(this.uri, { params }).toPromise();
  }
  exportCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.uri}/getCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(`${this.uri}/getPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }
}
