import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';


@Injectable({
  providedIn: 'root'
})
export class VipService {

  url = `${this.settings.API_BASE_URL}/vip`;
  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  getSubsciberList(): Promise<any> {
    return this.http.get<any>(this.url).toPromise();
  }

  getSubsciberListWithOffset(params: any, isInterestedInJoiningInvestorsGroup?: any): Promise<any> {
    if (isInterestedInJoiningInvestorsGroup) {
      Object.assign(params, { isInterestedInJoiningInvestorsGroup });
    }
    return this.http.get(this.url, { params }).toPromise();
  }

  exportCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.url}/getCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(`${this.url}/getPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }
}
