import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClearApplyBtnComponent } from './clear-apply-btn/clear-apply-btn.component';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [ClearApplyBtnComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [
    ClearApplyBtnComponent
  ]
})
export class ClearApplyBtnModule { }
