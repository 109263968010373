import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

interface Case {
  reportingUserId: string;
  reportingUsersMorID: string;
  reportedAgainstToUserId: string;
  reportedAgainstToMoRId: string;
  attachment: string;
  reportedOn: string;
  description?: string;
}

interface Violation {
  streamID: string;
  cases: Case[];
  createdAt: string;
  host: string;
  userEntries: any;
}

@Injectable({
  providedIn: 'root'
})
export class ViolationService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  getViolationList(params = {}): Promise<any> {
    
    const url = `${this.settings.API_BASE_URL}/violation/getViodetails`;
    return this.http.get(url, { params }).toPromise();
  }

  countByType(): Promise<any> {
    
    const url = `${this.settings.API_BASE_URL}/violation/countByType`;
    return this.http.get(url).toPromise();
  }

  exportCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    const url = `${this.settings.API_BASE_URL}/violation/exportCsv`;
   return this.http.get(url, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    const url = `${this.settings.API_BASE_URL}/violation/exportPdf`;
   return this.http.get(url, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }


  async getViolationDetails(id: any): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/violation/${id}`;
    return this.http.get(url).toPromise();
  }

  updateCaseById(id: string, data: any): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/violation/case/${id}`;
    return this.http.put(url, data).toPromise();
  }

  updateReportById(id: string, userId:string, data: any): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/violation/updateReport/${id}/${userId}`;
    return this.http.put(url, data).toPromise();
  }

  getViolationReport(params : any): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/violation/getViolationReport`;
    return this.http.get(url, {params}).toPromise();
  }

  getBanUser(params: any): Promise<any> {
    
    const url = `${this.settings.API_BASE_URL}/violation/getBanDetail`;
    return this.http.get(url, { params }).toPromise();
  }

  getViolationCount(params : any): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/violation/getViolationsCount`;
    return this.http.get(url, {params}).toPromise();
  }

  getStreamIdAndMorId(): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/violation/getStreamIdAndMorId`;
    return this.http.get(url).toPromise();
  }
}
