import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})

export class BeansDiamondService {

  constructor(private http: HttpClient, private settingsServe: SettingsService) { }
  private uri = `${this.settingsServe.API_BASE_URL}/beans_to_diamonds`;

  exportApprovedCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.uri}/approved/getCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }


  getBeansDiamond(params = {}): Promise<any> {
    return this.http.get<any>(`${this.uri}/getByType`, { params }).toPromise();
  }

  getTotalBeansDiamond(params = {}): Promise<any> {
    return this.http.get<any>(`${this.uri}/getTotal`, { params }).toPromise();
  }

  getTotalDiamondToFiat(params = {}): Promise<any> {
    return this.http.get<any>(`${this.uri}/getTotalDiamondToFiat`, { params }).toPromise();
  }

  getTotalDiamondToBean(params = {}): Promise<any> {
    return this.http.get<any>(`${this.uri}/getTotalDiamondToBean`, { params }).toPromise();
  }

  getTotalDiamondPurchase(params = {}): Promise<any> {
    return this.http.get<any>(`${this.uri}/getTotalDiamondPurchase`, { params }).toPromise();
  }

  exportCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.uri}/getCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(`${this.uri}/getPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  exportFiatCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.uri}/getFiatCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportFiatPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(`${this.uri}/getFiatPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  exportDiamondPurchaseCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.uri}/getDiamondPurchaseCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportDiamondPurchasePdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(`${this.uri}/getDiamondPurchasePdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  exportDiamondToBeanCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    return this.http.get(`${this.uri}/getDiamondToBeanCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportDiamondToBeanPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    return this.http.get(`${this.uri}/getDiamondToBeanPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  getDiamondToBeanIds(params = {}): Promise<any> {
    return this.http.get<any>(`${this.uri}/ids`).toPromise();
  }

  getDiamondToBeanDetail(transactionId: any): Promise<any> {
    return this.http.get<any>(this.uri + `/diamondToRupee/${transactionId}`,).toPromise();
  }

  updateStatusOfDiamondToRupee(transactionId: String, data: any): Promise<any> {
    return this.http.put<any>(this.uri + `/${transactionId}`, data).toPromise();
  }

}

export interface BeansDiamondQueryParams {
  [key: string]: any;
}