import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { BannerService } from 'src/app/services/banner/banner.service';
import { BeansDiamondService } from 'src/app/services/BeansDiamond/BeansDiamond-service.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { StaffService } from 'src/app/services/staff/staff.service';
import { EditService } from 'src/app/services/users/edit.service';
import { ViolationService } from 'src/app/services/violation/violation.service';
import { VipService } from 'src/app/services/vip/vip.service';
import { StreamManagementService } from 'src/app/streams/stream-management.service';

@Component({
  selector: 'app-export-report-btn',
  templateUrl: './export-report-btn.component.html',
  styleUrls: ['./export-report-btn.component.css']
})
export class ExportReportBtnComponent implements OnInit {

  constructor(private userService: EditService,
    private staffService: StaffService,
    private giftService: GiftService,
    private bannerService: BannerService,
    private beansDiamondService: BeansDiamondService,
    private streamService: StreamManagementService,
    private violation: ViolationService,
    private vipService: VipService,
    private router: Router) { }

  ngOnInit(): void {
  }

  exportData(event: any) {
    const url = this.router.url;
    switch (url) {
      case '/account':
        if (event === 'csv') {
          this.exportUserCsv();
        }
        if (event === 'pdf') {
          this.exportUserPdf();
        }
        break;
      case '/vip':
        if (event === 'csv') {
          this.exportVipUserCsv();
        }
        if (event === 'pdf') {
          this.exportVipUserPdf();
        }
        break;
      case '/staff':
        if (event === 'csv') {
          this.exportStaffCsv();
        }
        if (event === 'pdf') {
          this.exportStaffPdf();
        }
        break;
      case '/gift':
        if (event === 'csv') {
          this.exportGiftCsv();
        }
        if (event === 'pdf') {
          this.exportGiftPdf();
        }
        break;
        case '/giftRequest':
          if(event === 'csv'){
            this.exportGiftRequestCsv();
          }
          if(event === 'pdf'){
            this.exportGiftRequestPdf();
          }
          break;
          case '/livegift':
            if(event === 'csv'){
              this.exportLiveGiftCsv();
            }
            if(event === 'pdf'){
              this.exportLiveGiftPdf();
            }
            break;

      case '/banners':
        if (event === 'csv') {
          this.exportBannerCsv();
        }
        if (event === 'pdf') {
          this.exportBannerPdf();
        }
        break;
      case '/beansDiamond':
        if (event === 'csv') {
          this.exportBeansToDiamondCsv();
        }
        if (event === 'pdf') {
          this.exporBeansToDiamondsPdf();
        }
        break;

      case '/diamondFiat':
        if (event === 'csv') {
          this.exportDiamondToFiatCsv();
        }
        if (event === 'pdf') {
          this.exporDiamondToFiatPdf();
        }
        break;

      case '/diamondPurchase':
        if (event === 'csv') {
          this.exportDiamondPurchaseCsv();
        }
        if (event === 'pdf') {
          this.exporDiamondPurchasePdf();
        }
        break;

      case '/diamondBean':
        if (event === 'csv') {
          this.exportDiamondToBeanCsv();
        }
        if (event === 'pdf') {
          this.exporDiamondToBeanPdf();
        }
        break;

      case '/streams':
        if (event === 'csv') {
          this.exportStramCsv();
        }
        if (event === 'pdf') {
          this.exportStreamPdf();
        }
        break;

      case '/violation':
        if (event === 'csv') {
          this.exportViolationCsv();
        }
        if (event === 'pdf') {
          this.exportViolationPdf();
        }
        break;

      default:
        break;
    }


  }

  async exportStramCsv() {
    const response = await this.streamService.exportCsv();
    this.saveAsCSVFile(response.body, 'streams-');

  }
  async exportStreamPdf() {
    const response = await this.streamService.exportPdf();
    this.saveAsPdfFile(response.body, 'streams-');

  }

  async exportViolationCsv() {
    const response = await this.violation.exportCsv();
    this.saveAsCSVFile(response.body, 'violation-');

  }
  async exportViolationPdf() {
    const response = await this.violation.exportPdf();
    this.saveAsPdfFile(response.body, 'violation-');

  }
  async exportUserCsv() {
    const response = await this.userService.exportCsv();
    this.saveAsCSVFile(response.body, 'App-user-');

  }
  async exportUserPdf() {
    const response = await this.userService.exportPdf();
    this.saveAsPdfFile(response.body, 'App-user-');

  }

  async exportBannerCsv() {
    const response = await this.bannerService.exportCsv();
    this.saveAsCSVFile(response.body, 'banners-');

  }
  async exportBannerPdf() {
    const response = await this.bannerService.exportPdf();
    this.saveAsPdfFile(response.body, 'banners-');

  }

  async exportGiftCsv() {
    const response = await this.giftService.exportCsv();
    this.saveAsCSVFile(response.body, 'gift-');

  }
  async exportGiftPdf() {
    const response = await this.giftService.exportPdf();
    this.saveAsPdfFile(response.body, 'gift');

  }
  async exportLiveGiftCsv() {
    const response = await this.giftService.exportLiveGiftCsv();
    this.saveAsCSVFile(response.body,'livegift-');

  }
  async exportLiveGiftPdf() {
    const response = await this.giftService.exportLiveGiftPdf();
    this.saveAsPdfFile(response.body,'livegift-');

  }
  async exportGiftRequestCsv() {
    const response = await this.giftService.exportGiftRequestCsv();
    this.saveAsCSVFile(response.body,'giftreuest-');

  }
  async exportGiftRequestPdf() {
    const response = await this.giftService.exportGiftRequestPdf();
    this.saveAsPdfFile(response.body,'giftrequest');

  }
  async exportStaffCsv() {
    const response = await this.staffService.exportCsv();
    this.saveAsCSVFile(response.body, 'staff-');

  }
  async exportStaffPdf() {
    const response = await this.staffService.exportPdf();
    this.saveAsPdfFile(response.body, 'staff');

  }

  async exportDiamondToFiatCsv() {
    const response = await this.beansDiamondService.exportFiatCsv();
    this.saveAsCSVFile(response.body, 'diamonds-to-fiat');

  }
  async exporDiamondToFiatPdf() {
    const response = await this.beansDiamondService.exportFiatPdf();
    this.saveAsPdfFile(response.body, 'diamonds-to-fiat');

  }

  async exportDiamondPurchaseCsv() {
    const response = await this.beansDiamondService.exportDiamondPurchaseCsv();
    this.saveAsCSVFile(response.body, 'diamonds-purchase');

  }
  async exporDiamondPurchasePdf() {
    const response = await this.beansDiamondService.exportDiamondPurchasePdf();
    this.saveAsPdfFile(response.body, 'diamonds-purchase');

  }

  async exportDiamondToBeanCsv() {
    const response = await this.beansDiamondService.exportDiamondToBeanCsv();
    this.saveAsCSVFile(response.body, 'diamonds-to-bean');

  }
  async exporDiamondToBeanPdf() {
    const response = await this.beansDiamondService.exportDiamondToBeanPdf();
    this.saveAsPdfFile(response.body, 'diamonds-to-bean');

  }

  async exportBeansToDiamondCsv() {
    const response = await this.beansDiamondService.exportCsv();
    this.saveAsCSVFile(response.body, 'bean-to-diamonds');

  }
  async exporBeansToDiamondsPdf() {
    const response = await this.beansDiamondService.exportPdf();
    this.saveAsPdfFile(response.body, 'bean-to-diamonds');

  }

  saveAsPdfFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename = fileName + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + date.getUTCMinutes();
    const data: Blob = new Blob([buffer], { type: "application/pdf;charset=utf-8" });
    FileSaver.saveAs(data, filename + ".pdf");
  }

  saveAsCSVFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename = fileName + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + date.getUTCMinutes();
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    FileSaver.saveAs(data, filename + ".csv");
  }

  async exportVipUserCsv() {
    const response = await this.vipService.exportCsv();
    this.saveAsCSVFile(response.body, 'Vip-subscription-user-');

  }
  async exportVipUserPdf() {
    const response = await this.vipService.exportPdf();
    this.saveAsPdfFile(response.body, 'Vip-subscription-user-');

  }

}
