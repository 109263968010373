<div class="container edit-dialog-container">
    <h2 mat-dialog-title class="title"> Edit Category Name</h2>
    <div class='row'>
        <div class='col-sm-3'>
            <label>Old Name:</label>
        </div>
        <div class='col-sm-3'>
            <label class="label1">{{data?.category?.name}}</label>
        </div>
    </div>
    <!-- Rename Input -->
    <form [formGroup]='editCategory' (ngSubmit)="updateGiftServe()">
        <div class='row'>
            <div class='col-sm-3'>
                <label class="input-label">Rename:</label>
            </div>
            <div class='col-sm-6'>
                <mat-form-field appearance="outline" class="w-100 ">
                    <input type="text" matInput placeholder="Ex: Most Popular" class="w-100" formControlName="name"
                        required>
                    <mat-error *ngIf="editCategory.get('rename')?.errors?.required">Please
                        enter rename </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!-- Confirm Input -->
        <div class='row'>
            <div class='col-sm-3'>
                <label class="input-label">Confirm:</label>
            </div>
            <div class='col-sm-6'>
                <mat-form-field appearance='outline' class='w-100 '>
                    <input type='text' matInput placeholder="Ex: Most Popular" class="w-100" formControlName="confirm"
                        required>
                    <mat-error *ngIf="editCategory.get('confirm')?.errors?.required">Please
                        enter confirm name</mat-error>
                    <mat-error *ngIf="editCategory.get('confirm')?.errors?.noMatch">Please
                        Should match with Rename</mat-error>
                </mat-form-field>
            </div>
        </div>


        <!-- Note -->
        <p class="note">Note: This will not change the previous entires</p>

        <!-- Button -->
        <div class='row button-section'>
            <div class='col-sm-6'>
                <button type="button" class='btn btn-danger cancel-btn' mat-dialog-close>Cancel</button>
            </div>
            <div class='col-sm-6'>
                <button type="submit" class='btn btn-success' [disabled]="editCategory.invalid">Save</button>
            </div>
        </div>
    </form>





</div>