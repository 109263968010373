<div class="d-flex flex-row justify-content-center my-flex-container" mat-dialog-title>
    <h1>Confirm Rejection</h1>
</div>

<div>
    <h3>Are you sure you want to reject this Event?</h3>

    <div class="d-flex flex-row justify-content-center my-flex-container">
        <button class=" btn btn-primary form-button mr-2" type="button" (click)="back()">Back</button>

        <button class="btn btn-danger form-button" type="button" (click)=setStatus()>Reject</button>
    </div>


</div>