import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HostSalaryReportRoutingModule } from './host-salary-report-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { ClearApplyBtnModule } from '../components/clear-apply-btn/clear-apply-btn.module';
import { MatSortModule } from '@angular/material/sort';
import { ExportReportBtnModule } from '../components/export-report-btn/export-report-btn.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SalaryReportDialogComponent } from './salary-report-dialogue/salary-report-dialog/salary-report-dialog.component';
import { HostSalaryReportComponent } from './host-salary-report.component';


@NgModule({
  declarations: [
    HostSalaryReportComponent,
    SalaryReportDialogComponent
  ],
  imports: [
    CommonModule,
    HostSalaryReportRoutingModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    ExportReportBtnModule,
    MatSortModule,
    ClearApplyBtnModule,
    MatButtonModule,
    FormsModule,
  ],
  entryComponents: [SalaryReportDialogComponent]
})
export class HostSalaryReportModule { }
