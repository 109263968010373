import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/common/commonService';
import { EventQueryParams, EventServiceService } from 'src/app/services/events/event-service.service';

@Component({
  selector: 'app-approve-f2',
  templateUrl: './approve-f2.component.html',
  styleUrls: ['./approve-f2.component.css']
})
export class ApproveF2Component implements OnInit {
  id = '';
  isStatusUpdated = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { id: '' },
    public dialogRef: MatDialogRef<ApproveF2Component>,
    private service: EventServiceService,
    private snackbar: MatSnackBar,
    private commonService: CommonService) {
    this.id = data.id;
  }

  ngOnInit(): void {
  }

  async setStatus(): Promise<void> {
    this.dialogRef.close(true);
    const params: EventQueryParams = {
      hrApprovalStatus: 'APPROVED'
    };
    try {
      this.commonService.showLoading();
      const result = await this.service.updateHrStatus(this.id, params);
      if (result) {
        this.service.setHrStatus('APPROVED');
        this.snackbar.open('Event Approved', 'close', { duration: 2000 });
      } else {
        this.snackbar.open('Could not approve', 'close', { duration: 2000 });
      }
      this.commonService.hideLoading();
    } catch (error: any) {
      this.commonService.hideLoading();
      this.snackbar.open(error?.error?.message ?? 'Failed server request', 'close', { duration: 2000 });
    }
  }

  back(): void {
    this.dialogRef.close();
  }

}
