import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})

export class CommonService {


  constructor(
    public spinner: NgxSpinnerService) {

  }



  //Hide loader
  public hideLoading() {
    setTimeout(() => {
      /// spinner ends after 5 seconds 
      this.spinner.hide();
    }, 100);
  }

      //show loader 
      public showLoading() {
        this.spinner.show();
    }

}