<mat-toolbar class="d-flex justify-content-between mat-elevation-z2">
    <div class="d-flex">
        <button mat-icon-button (click)="toggleSideNav()">
            <mat-icon>menu</mat-icon>
        </button>

        <!-- Titles Will change dynamically based on the page -->
        <h1 class="mat-h3">{{title}}</h1>
    </div>
    <div>

        <button mat-icon-button aria-label="Account" mat-button [matMenuTriggerFor]="menuNotification"
            (click)="notify()">
            <mat-icon>notifications</mat-icon>
        </button>

        <mat-menu yPosition="below" #menuNotification="matMenu" class="menu-btn">
            <div class=" notification-heading">
                <div class="row">
                    <div class="col-9"> 
                        <span matBadge="2" matBadgeOverlap="false" matBadgeColor="warn">Notifications</span>
                    </div>
                    <div class="col-3"><button class="btn btn-default btn-sm clear-btn" (click)="clear()">Clear all</button> </div>
                </div>
            </div>
            <div *ngIf="isnotify" class="notify-area">
                <div class="notification-title">Today</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9 first-span">
                                This is a Text Field and when it is not opened
                            </span>
                            <span class=" col-3 notification-time">2 min</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9">
                                This is a Text Field and when it is opened
                            </span>
                            <span class="col-3 notification-time">1hr</span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title">Yesterday
                </div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9">
                                This is a test field when the notification is in two lines just to show how beautiful it
                                looks
                            </span>
                            <span class="col-3 notification-time">14:12</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9">This is a Text Field
                            </span>
                            <span class="col-3 notification-time">12:03</span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">08:15</span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field </span>
                            <span class="col-3 notification-time">08:15
                            </span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field </span>
                            <span class="col-3 notification-time">08:15
                            </span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field </span>
                            <span class="col-3 notification-time">08:15
                            </span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field </span>
                            <span class="col-3 notification-time">08:15
                            </span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field </span>
                            <span class="col-3 notification-time">08:15
                            </span>
                        </span>
                    </li>
                </ul>
                <hr>
                <div class="notification-title ">Mon 25 March 19</div>
                <ul>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field
                            </span>
                            <span class="col-3 notification-time">09:45</span>
                        </span>
                    </li>
                    <li class="container-fluid notification-data">
                        <span class="row">
                            <span class="col-9"> This is a Text Field </span>
                            <span class="col-3 notification-time">08:15
                            </span>
                        </span>
                    </li>
                </ul>
            </div>
        </mat-menu>
        <!-- Account Dropdown Menu : starts-->
        <button mat-button [matMenuTriggerFor]="menu" aria-label="Account" *ngIf="isLoggedIn">
            <b class="d-none d-sm-inline-block pl-1">Welcome, {{ UserDetail.firstName }} {{ UserDetail.lastName }}&nbsp;&nbsp;</b>
            <mat-icon>account_circle</mat-icon>
            <mat-icon class="d-none d-sm-inline-block">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <!-- <button (click)="openChangePasswordModal()" mat-menu-item>
                <mat-icon color="accent">security</mat-icon>
                <span>Change Password</span>
            </button> -->
            <button routerLink='/profile' mat-menu-item>
                <mat-icon color="accent">security</mat-icon>
                <span>View Profile</span>
            </button>
            <button (click)="logout()" mat-menu-item>
                <mat-icon color="warn">power_settings_new</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
        <!-- Account Dropdown Menu : ends -->

    </div>
</mat-toolbar>