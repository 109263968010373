import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class EditService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  exportApprovedBeans(month: number, year: number) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
  
    let params = new HttpParams()
      .set('month', month.toString())
      .set('year', year.toString());
  
    return this.http.get(`${this.settings.API_BASE_URL}/users/getHostSalaryCsv`, { 
      headers: headers, 
      responseType: 'text', 
      observe: 'response',
      params: params
    }).toPromise();
  }
  


  getUserbyID(val: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/${val}`).toPromise();
  }

  getUsersMorId(): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getMorIds`).toPromise();
  }

  recoveryAccount(userId:any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/recoverAcc/${userId}`).toPromise();
  }

  resetDevice(morId:any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/resetDevice/${morId}`).toPromise();
  }

  getUsersMorIdByClub(clubId: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getMorIds/${clubId}`).toPromise();
  }

  exportCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.settings.API_BASE_URL}/users/getCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.settings.API_BASE_URL}/users/getPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  getUserbyToken(): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getUserByToken`).toPromise();
  }

  getUserbyMORID(morId: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/user/${morId}`).toPromise();
  }

  getUserProfilebyMORID(morId: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getUserProfile/${morId}`).toPromise();
  }

  getUserDevice(morId: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/deviceDetails/${morId}`).toPromise();
  }

  getUserBankDetails(id: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getBankDetail/${id}`).toPromise();
  }

  updateUserByID(data: any, val: any): Promise<any> {
    return this.http.put(`${this.settings.API_BASE_URL}/users/${val}`, data).toPromise();
  }

  updatePassword(data: any, userId: any): Promise<any> {
    return this.http.put(`${this.settings.API_BASE_URL}/users/${userId}`, data).toPromise();
  }

  deleteProfile(data: any, userId: any): Promise<any> {
    return this.http.put(`${this.settings.API_BASE_URL}/users/${userId}`, data).toPromise();
  }

  getBroadcast(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/getLeaderBoardBroadcast`, data).toPromise();
  }

  getEvents(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/getEvent/`, data).toPromise();
  }

  addReward(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/addReward/`, data).toPromise();
  }

  getHostSalaryReport(params: any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getHostSalary/`, { params }).toPromise();
  }

  generateSalaryReport(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/generateSalary`, data).toPromise();
  }

  updateSalaryStatus(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/updateSalaryStatus`, data).toPromise();
  }

  getEventsByDate(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/getEventByDate`, data).toPromise();
  }

  getViewer(data: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/getLeaderBoardViewer`, data).toPromise();
  }

  linkAssests(userId: any, loanAccountNumber: any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/users/myassets`, { userId, loanAccountNumber }).toPromise();
  }

  getUsers(params: any, isInterestedInJoiningInvestorsGroup?: any): Promise<any> {
    if (isInterestedInJoiningInvestorsGroup) {
      Object.assign(params, { isInterestedInJoiningInvestorsGroup });
    }
    const url = `${this.settings.API_BASE_URL}/users/getUsersDetails`;
    return this.http.get(url, { params }).toPromise();
  }

  getClubs(): Promise<any> {

    const url = `${this.settings.API_BASE_URL}/users/getClubDetails`;
    return this.http.get(url).toPromise();
  }



  getAllUsers(): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/users/getUsersDetails`).toPromise();

  }
}


export enum role {
  USER = 'USER',
  ADMIN = 'ADMIN',
  COUNTRY_HEAD = 'COUNTRY_HEAD',
  ZONAL_HEAD = 'ZONAL_HEAD',
  HR = 'HR',
  CLUB = 'CLUB'
}

