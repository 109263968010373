import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

export interface GiftCategory {
  id: string;
  name: string;
  [key: string]: any;
}

export enum UtilityType {
  DIAMONDS = 'DIAMONDS',
  COINS = 'COINS',
  FREE = 'FREE'
}

export interface GiftCategoryUpdateData {
  name: string;
}
export interface GiftIdForEvents {
  categories: string;
}

export interface GiftQueryParams {
  sortField?: string;
  sortValue?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  uri = `${this.settingsServe.API_BASE_URL}/gifts`;
  categoryUri = `${this.uri}/categories`;
  categories$: Promise<GiftCategory[]> | null;

  constructor(
    private settingsServe: SettingsService,
    private http: HttpClient
  ) { }

  getCategories(): Promise<any> {
    if (!this.categories$) {
      this.categories$ = this.http.get<any>(this.categoryUri + '/list').toPromise();
      return this.categories$;
    }
    return this.categories$;
  }

  updateCategory(id: string, data: GiftCategoryUpdateData): Promise<GiftCategory> {
    this.categories$ = null;
    return this.http.put<GiftCategory>(`${this.uri}/admin/categories/${id}`, data).toPromise();
  }


  uploadGitImage(gifFile: File): Promise<any> {
    const data = new FormData();
    data.append('image', gifFile);
    return this.http.post(this.uri, {}).toPromise();
  }

  uploadLiveGiftImageImage(image: File): Promise<any> {
    const data = new FormData();
    data.append('image', image);
    return this.http.post(`${this.uri}/upload`, data).toPromise();
  }

  addGift(data: any): Promise<any> {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item: any) => {
          formData.append(key, item);
        });
        return;
      }
      formData.append(key, data[key]);
    });
    return this.http.post(this.uri, formData).toPromise();
  }

  updateGift(id: string, data: any): Promise<any> {
    console.log(data);
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item: any) => {
          formData.append(key, item);
        });
        return;
      }
      formData.append(key, data[key]);
    });
    return this.http.patch(`${this.uri}/${id}`, formData).toPromise();
  }

  getGifts(params: GiftQueryParams = {}): Promise<any> {
    return this.http.get(this.uri, { params }).toPromise();
  }

  getGiftNameList(): Promise<any> {
    return this.http.get(this.uri + "/list/names").toPromise();
  }

  getGiftsRequest(params: GiftQueryParams = {}): Promise<any> {
    return this.http.get(`${this.uri}/request/gifts`, { params }).toPromise();
  }

  getRequestgiftDetail(id: any): Promise<any> {
    return this.http.get(`${this.uri}/request/giftdetail/${id}`).toPromise();
  }

  getRequestIds(): Promise<any> {
    return this.http.get(`${this.uri}/request/ids`).toPromise();
  }

  updateRequestgiftDetail(id: any, params: any): Promise<any> {
    return this.http.patch(`${this.uri}/request/giftdetail/${id}`, params).toPromise();
  }

  getAllGifts(): Promise<any> {
    return this.http.get(this.uri + '/allGifts').toPromise();
  }

  getGiftCategory(): Promise<any> {
    return this.http.get(this.uri + '/admin/categories').toPromise();
  }

  getGiftById(id: string): Promise<any> {
    return this.http.get(`${this.uri}/${id}`).toPromise();
  }
  // For Events
  getPhysicalGiftsForEventById(params = {}): Promise<any> {
    return this.http.get<any>(this.uri, { params }).toPromise();
  }

  exportCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getCsv`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getPdf`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  exportLiveGiftCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getCsv/live`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportLiveGiftPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getPdf/live`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }

  exportGiftRequestCsv() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getCsv/request`, { headers: headers, responseType: 'text', observe: 'response' }).toPromise();
  }

  exportGiftRequestPdf() {
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf; charset=utf-8');
    // return this.http.post(url, filterParameter, {  headers: headers,  responseType: 'text', observe: 'response' });
    return this.http.get(`${this.uri}/getPdf/request`, { headers: headers, responseType: 'blob', observe: 'response' }).toPromise();
  }
}
