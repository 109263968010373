import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey = 'AUTH_TOKEN';
  private role = 'ROLE';

  constructor() { }

  saveToken(data: string, role:string): void {
    const details = {
      'AUTH_TOKEN' :   data,
      'ROLE': role
    }
    window.localStorage.setItem('details',JSON.stringify(details));
  }

  getToken(): string | null {
    const storage = window.localStorage.getItem('details');
    const details = storage ? JSON.parse(storage) : {};
    return details?.AUTH_TOKEN;
  }

  isTokenExist(): boolean {
    return this.getToken() ? true : false;
  }

  getTokenData(): any {
    const storage = window.localStorage.getItem('details');
    const details = storage ? JSON.parse(storage) : {};
    return details.ROLE;

  }

  removeToken(): void {
    window.localStorage.removeItem('details');
  }
}
